<template>
    <el-row :gutter="20">
      <el-col :span="14">
        <el-form v-loading="loading" :model="form" label-width="8rem" style="padding: 2rem;">
          <el-form-item label="七牛云远程附件">
            <el-radio v-model="form.qi_niu_status" :label="false">关闭</el-radio>
            <el-radio v-model="form.qi_niu_status" :label="true">开启</el-radio>
          </el-form-item>
          <el-form-item label="Accesskey">
            <el-input v-model="form.qi_niu_access_key"></el-input>
            <div class="y-desc">用于签名的公钥</div>
          </el-form-item>
          <el-form-item label="Secretkey">
            <el-input v-model="form.qi_niu_secret_key"></el-input>
            <div class="y-desc">用于签名的私钥</div>
          </el-form-item>
          <el-form-item label="Bucket">
            <el-input v-model="form.qi_niu_bucket"></el-input>
            <div class="y-desc">请保证bucket为可公共读取的</div>
          </el-form-item>
          <el-form-item label="Url">
            <el-input v-model="form.qi_niu_url"></el-input>
            <div class="y-desc">七牛支持用户自定义访问域名。注：url开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
          </el-form-item>
          <el-form-item >
            <el-button @click="test">测试配置(测试无需开启，修改配置需保存后测试)</el-button>
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col v-if="form.id" :span="8">
        <div style="padding: 2rem;width: 100%">
          <div style="margin-bottom: 2rem;text-align: right">
            <el-button @click="local2remote">一键上传本地附件至七牛</el-button>
          </div>
          <div style="margin-top: 2rem">
            <div>注意事项</div>
            <div class="y-desc" style="margin-bottom: 1rem">一键上传占用服务器资源较大，建议仅在无活动时操作，或直接手动将站点attachment/目录下的文件夹上传至七牛</div>
            <div class="y-desc" style="margin-bottom: 1rem">上传任务启动后即可离开此页面，后台将自动上传本地附件直至全部上传完成</div>
            <div class="y-desc" style="margin-bottom: 1rem">一键上传不会删除本地附件文件，仅将本地附件上传至七牛</div>
            <div class="y-desc">富文本内容引用图片为引用完整url，故站点开启远程附件后已保存的富文本内容引用的图片仍为原url</div>
          </div>
        </div>
      </el-col>
    </el-row>
</template>

<script>
export default {
  name: "attachment",
  data(){
    return{
      form:{
        qi_niu_status:false,
        qi_niu_access_key:"",
        qi_niu_secret_key:"",
        qi_niu_bucket:"",
        qi_niu_url:"",
      },
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.sys.manager.attachRemoteOpt().then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    local2remote(){
      this.$api.sys.manager.attachAllLocal2Remote().then(()=>{
        this.$message.success("上传任务启动成功");
      })
    },
    test(){
      this.$api.sys.manager.attachRemoteTest().then(()=>{
        this.$message.success("远程附件配置正常");
      })
    },
    submit(){
      this.$api.sys.manager.attachRemoteOptEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        location.reload();
      })
    }
  }
}
</script>

<style scoped>
.y-desc{
  line-height: 1rem
}
</style>